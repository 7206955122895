.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 0.6em;
  padding-bottom: 0.2em;
}

.row-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.column-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.icon {
  fill: var(--fg-color-frame);
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-start {
  position: relative;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  overflow: hidden; /* Ensure overflow is handled */
  /*white-space: nowrap; !* Make sure text is on one line *!*/
  text-overflow: ellipsis; /* Display ellipsis if text overflows */
  flex-grow: 1; /* Allow this div to grow and take available space */
}

.cont {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
}

.column-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.short-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.short-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  line-height: 24px;
  flex: 1;
}

.hidden {
  position: absolute;
  top: -1000px;
  pointer-events: none;
  visibility: hidden;
}

.icon-position {
  position: absolute;
  top: -4px;
  left: -4px;
  right: -10px;
}
