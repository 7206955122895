/*
 * Replace this with your own classes
 *
*/

.container {
  position: relative;
}

.shadow-top {
  content: '';
  position: absolute;
  top: -1pt; /* WORKAROUND: only Jesus know, why on iPhone there is a small gap with 0 which would actually be correct */
  left: 0;
  right: 0;
  height: 1em;
  background-image: linear-gradient(
    to top,
    rgba(var(--bg-color-dark1-rgb), 0),
    rgba(var(--bg-color-dark1-rgb), 1) 100%
  );
}

.shadow-bottom {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1em;
  background-image: linear-gradient(
    to bottom,
    rgba(var(--bg-color-dark1-rgb), 0),
    rgba(var(--bg-color-dark1-rgb), 1) 100%
  );
}
