/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
}

.header {
  margin: 1em;
  display: flex;
  flex-direction: row;
}

.select {
  font-size: x-large;
  margin-left: auto;
  width: 20%;
}
