/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  margin: 0 2.5em 0 2.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.input-container {
  margin-right: 0em;
}
.input {
  font-size: large;
  padding: 0.5em 0.8em;
}

.button-container {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}
