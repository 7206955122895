body {
  background-color: var(--bg-color-dark1);
  overflow: auto;
}

.logo {
  margin: 30px 54px;
  width: 100px;
  position: absolute;
}
