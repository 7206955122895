/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.svg {
  rotate: -45deg;
}
