.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: xx-large;
  display: flex;
  align-items: center;
  padding: 20px;
  font-weight: bold;
}

.description {
  font-size: 16px;
  font-weight: 400;
  padding: 20px;
  text-align: center;
  line-height: var(--line-height-desc);
}
