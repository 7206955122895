/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.dots {
  color: var(--fg-color-frame);
}
