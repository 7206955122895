.row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.text {
  font-size: 14px;
  font-weight: 600;
}
