.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.code {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  position: relative;
  top: -10px;
}
