.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(9, 15, 35, 0.7);
  z-index: 9999;
  justify-content: flex-end;
}

.message-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  margin: 10px;
  overflow: hidden;
}

.cancel-button {
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  margin: 10px 10px 30px;
}

.padding {
  padding: 10px;
}

.title {
  color: var(--fg-color-greyed);
  text-align: center;
}


.buttons-container {
  background-color: var(--fg-color-dark1);
  padding: 20px;
  padding-bottom: 30px;
}

.warning {
  color: var(--fg-color-warning);
}

.button-confirm {
  border-radius: 18px;
  color: var(--fg-color-warning);
}

.button-cancel {
  border-radius: 18px;
  color: var(--fg-color-frame);
}

.test-class {
  color: var(--fg-color-frame);
}
