.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.icon {
  font-weight: bold;
  font-size: 20px;
}

.location {
  white-space: nowrap;
}
