.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  /*padding: 18px 1em;*/
  width: 100%;
}

.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  min-width: 110px;
}
