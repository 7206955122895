/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 76px;
  height: 30px;
  position: relative;
  display: block;
}

.base {
  width: 72px;
  height: 26px;
  margin: 2px;
  border-radius: 13px;
  border-width: var(--outline-thickness-thin);
  border-style: solid;
  border-color: var(--fg-color-frame);
}
.base-active {
  width: 72px;
  height: 26px;
  margin: 2px;
  border-radius: 13px;
  background-color: var(--bg-color-frame);
}

.knob:checked {
  left: 46px;
}
.knob {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color-frame2);
  transition: left 150ms;
  appearance: none;
}
