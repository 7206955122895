.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.search-input {
  background-color: transparent;
  outline: none;
  border: 2px solid white;
  border-radius: 12px;
  color: white;
  height: 40px;
  width: 100%;
  font-size: 16px;
  padding-left: 40px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-46%);
}

.clear-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
