/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
  gap: 1em;
  align-items: center;
  justify-items: center;
  outline: 0px solid transparent;
}

.num-button {
  display: grid;
  width: 3.8em;
  height: 3.8em;
}
.back {
  fill: var(--fg-color-frame);
  grid-row: 4;
  grid-column: 3;
  width: 3.5em;
  height: 3.5em;
}
