/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

.border {
  border-style: solid;
  border-width: var(--outline-thickness-std);
  border-color: var(--fg-color-frame);
  border-radius: var(--radius-corner-std);
  padding: 0.5em;
}

.input {
  border: none;
  outline: none;
  font-size: medium;
  background-color: #00000000;
  color: var(--fg-color-bright);
}
.input:focus {
  border: none;
  outline: none;
  font-size: medium;
}

.button {
  width: 65%;
}
