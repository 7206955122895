.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  height: 35px;
  cursor: pointer;
  padding: 4px 5px 4px 10px;
  position: relative;
  background-color: rgba(var(--bg-color-dark1-rgb));
}

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dropbox-wrapper {
  display: flex;
  flex-direction: column;
  background-color: rgba(var(--bg-color-dark1-rgb), 0.8);
  border-radius: 12px;
  backdrop-filter: blur(5px);
  border: 1px solid var(--border-color);
  min-width: 100%;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px 15px;
  border-bottom: 1px solid var(--border-color);
  white-space: nowrap;
}

.item-no-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  cursor: pointer;
  white-space: nowrap;
}

.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: relative;
  left: -10px;
}
