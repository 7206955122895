/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.text {
  width: 100%;
  height: 100%;
  font-size: xx-large;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.plot {
  position: absolute;
  width: 100%;
  height: 100%;
}

.time-range {
  position: absolute;
  width: 11%;
  right: 1em;
  top: 2em;
}
.customers {
  position: absolute;
  width: 11%;
  right: 13%;
  top: 2em;
}
.sub {
  position: absolute;
  width: 11%;
  right: 25%;
  top: 2em;
}
