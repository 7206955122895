/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.std-icon {
  fill: white;
}
