/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.inner-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.title {
  font-size: large;
  margin-bottom: 6px;
}

.picker {
  width: 120px;
}

.confirm {
  margin: 0 auto;
  width: 75%;
}
