/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  min-height: 0;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 2em;
  padding-top: 8px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 20px;
}

.container-for-header {
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  min-height: 0;
  flex: 1;
  height: 100%;
  padding-top: 16px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 20px;
}

.title {
  font-size: x-large;
  font-weight: bold;
}
.title-for-header {
  font-size: x-large;
  font-weight: bold;
}
