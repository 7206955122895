.container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.title {
  font-size: 1em;
  font-weight: bold;
}

.description {
  font-size: 14px;
  padding: 0.5em 0;
  color: var(--fg-color-greyed);
}

.infos {
  display: flex;
  flex-direction: column;
}

.icon {
}
