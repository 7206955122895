.container {
  aspect-ratio: 1 / 1;
  height: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
}

.left-top-border {
  aspect-ratio: 1 / 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent; /* Ensures the inside of the div is transparent */
  box-sizing: border-box;
}

.left-top-border::before {
  content: '';
  position: absolute;
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  border-radius: 50%;
  border: 1.5px solid transparent; /* Make all borders transparent */
  border-top: 1.5px solid var(--border-color); /* Only top border is solid */
  transform: rotate(-45deg); /* Rotate the pseudo-element */
  transform-origin: 50% 50%; /* Ensure rotation is centered */
  box-sizing: border-box;
}

.right-top-border {
  aspect-ratio: 1 / 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent; /* Ensures the inside of the div is transparent */
  position: absolute;
  box-sizing: border-box;
}

.right-top-border::before {
  content: '';
  position: absolute;
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  border-radius: 50%;
  border: 1.5px solid transparent; /* Make all borders transparent */
  border-top: 1.5px solid var(--border-color); /* Only top border is solid */
  transform: rotate(45deg); /* Rotate the pseudo-element */
  transform-origin: 50% 50%; /* Ensure rotation is centered */
  box-sizing: border-box;
}

.right-bottom-border {
  aspect-ratio: 1 / 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent; /* Ensures the inside of the div is transparent */
  position: absolute;
  box-sizing: border-box;
}

.right-bottom-border::before {
  content: '';
  position: absolute;
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  border-radius: 50%;
  border: 1.5px solid transparent; /* Make all borders transparent */
  border-top: 1.5px solid var(--border-color); /* Only top border is solid */
  transform: rotate(135deg); /* Rotate the pseudo-element */
  transform-origin: 50% 50%; /* Ensure rotation is centered */
  box-sizing: border-box;
}

.left-bottom-border {
  aspect-ratio: 1 / 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent; /* Ensures the inside of the div is transparent */
  position: absolute;
  box-sizing: border-box;
}

.left-bottom-border::before {
  content: '';
  position: absolute;
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  border-radius: 50%;
  border: 1.5px solid transparent; /* Make all borders transparent */
  border-top: 1.5px solid var(--border-color); /* Only top border is solid */
  transform: rotate(-135deg); /* Rotate the pseudo-element */
  transform-origin: 50% 50%; /* Ensure rotation is centered */
  box-sizing: border-box;
}

.horizontal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 4px;
  background-color: var(--border-color);
}

.vertical {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 4px;
  background-color: var(--border-color);
}

.cross {
  position: absolute;
  aspect-ratio: 1 / 1;
  height: 100%;
}

.display-data {
  position: absolute;
  aspect-ratio: 1 / 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
