.input {
  width: 100%;

  padding-left: 10px;
  padding-right: 13px;
  padding-top: 6px;
  padding-bottom: 6px;

  border: solid var(--fg-color-bright) var(--outline-thickness-std);
  border-radius: 1em;

  color: var(--fg-color-frame);
  background: none;

  outline: none; /* Remove border in case of focus */

  font-size: large;
}
.input:disabled {
  border: solid var(--fg-color-greyed) var(--outline-thickness-std);
  color: var(--fg-color-greyed);
}
