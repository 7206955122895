/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.page {
  grid-row: 1/3;
  grid-column: 1;
  z-index: 1;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

.number-outer-container {
  display: block;
  position: relative;
}

.number-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.error-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid var(--fg-color-frame);
  border-radius: var(--radius-corner-std);
  background-color: var(--bg-color-dark1);
}
