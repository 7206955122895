/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}
.common {
  grid-column: 1/2;
  grid-row: 1/2;

  overflow-wrap: anywhere; /* let break long words ... note: break-word did not have the right effect */
  font: inherit;
}
.replica {
  visibility: hidden;
}
.edit {
  resize: none;
  overflow: hidden;
}
