/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.context-menu-anker {
  position: relative;
}

.context-menu-content {
  box-sizing: border-box;
  position: absolute; /* position is set via elememnt style in render function */

  width: max-content;

  z-index: 10;

  background-color: var(--bg-color-dark3);
  border: 1pt solid var(--bg-color-dark1);
  border-radius: var(--radius-corner-small);
}
