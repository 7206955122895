/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.button {
  width: 40px;
  height: 40px;
  fill: var(--fg-color-bright);
}
