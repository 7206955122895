
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 3px;
}

.line {
  height: 2px;
  width: 100%;
  background-color: #1cade4;
  border-radius: 50px;
}
