/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.group {
  font-size: large;
  margin-bottom: 6px;
}
.option-container {
  display: flex;
  flex-direction: column;
}

.option {
  font-size: medium;
  color: var(--fg-color-frame);
  margin: 6px 0;
}
