.container {
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 2px;
  border-radius: 4px;
  margin-bottom: 10px;

  margin-left: -7px;
  padding-left: 7px;

  margin-top: 10px;
}

.symbol-container {
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-container span {
  margin-left: 1.2em;
  margin-right: 1.2em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.multiple-info {
  background-color: rgb(255, 255, 255, 0.08);
  width: 29px;
  height: 29px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 2em;
}
